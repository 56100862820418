<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="customer-contracts"
                          base-table="customer-contracts"
                          columnsPrefix="customer-contract.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.title.customer-contract"
                          :actions-list="getActions()"
                >
                    <template #cell(sign_link)="row">
                        <b-dropdown
                            :variant="signature.signed ? 'success':'primary'"
                            size="sm"
                            v-for="signature in row.item.signatures"
                            class="mr-2"
                            :key="signature.id"
                            style="position: inherit"
                        >
                            <template #button-content>
                                {{ signature.signer_key }}
                            </template>
                            <b-dropdown-item
                                @click="$root.copyToClipboard(row.item.sign_link+signature.hash)"
                            >
                                <font-awesome-icon class="mr-2" icon="copy"/>
                                Copy
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('qr-modal', row.item.sign_link+signature.hash)"
                            >
                                <font-awesome-icon class="mr-2" icon="qrcode"/>
                                QR
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    <template #cell(kunden_id)="row">
                        <a href="#"
                           @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.kunden_id.id}, refreshTable)">
                            {{ row.item.kunden_id.name }}
                        </a>
                    </template>
                    <template #cell(file)="row">
                        <a href="#" @click.prevent="customerContractUrl(row.item.file)">{{ row.item.file }}</a>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item @click="sendMail(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="envelope"/>
                                {{ $t('common.button.send_mail') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-if="row.item._delete"
                                             @click="deleteContract(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.title.delete') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('todo-modal', {contract_id: row.item.id, customer_id: row.item.kunden_id.id}, refreshTable)">
                                {{ $t('common.button.add_todo') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="downloadCustomerContractUrl(row.item.file)">
                                {{ $t('common.button.download') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'CustomerContract',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('CustomerContract', ['getContractsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CustomerContract/fetchContracts', 'getContractsTable');
        },
        ctxBaseParams() {
            return {
                'startDate': this.dateStart,
                'endDate': this.dateEnd,
            }
        },
        customerContractUrl(file) {
            this.$root.openDocument(file, 'customer_contracts')
        },
        downloadCustomerContractUrl(file) {
            this.$root.downloadDocument(file, 'customer_contracts')
        },
        deleteContract(id) {
            this.$removeConfirm('CustomerContract/deleteContract', {
                contractId: id
            }, this.refreshTable)
        },
        deleteSelected() {
            this.$removeConfirm('CustomerContract/deleteContracts', this.selected.map(row => row.id), this.refreshTable)
        },
        sendMail(id) {
            this.$root.$children[0].openModal('email-modal', {
                documentId: id,
                docType: 'contract'
            }, this.refreshTable, {})
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.createCustomerContract'),
                    icon: 'plus',
                    hide: !this.$root.hasAccess('contract', 'create'),
                    click: () => {
                        this.$root.$children[0].openModal('customer-contract-modal', {}, this.refreshTable)
                    },
                },
                {
                    title: this.$t('common.button.delete'),
                    icon: 'trash',
                    hide: !this.$root.hasAccess('contract', 'delete'),
                    click: () => {
                        this.deleteSelected();
                    },
                },
            ]
        }
    }
}
</script>